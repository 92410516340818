// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBN9wYeyAnWdUxOIoalx1YGzuTojdUBjcc",
    authDomain: "mybarber-682fa.firebaseapp.com",
    projectId: "mybarber-682fa",
    storageBucket: "mybarber-682fa.appspot.com",
    messagingSenderId: "784428960849",
    appId: "1:784428960849:web:2f302d1df9218b7dbd7c70"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const messaging = getMessaging();
//
// // Add the public key generated from the console here.
// getToken(messaging, { vapidKey: 'BCKqsDD8SK1XNlNQfljAWMV6nF_o9pOY_5Xlhf8mq21AR4P1OgWIkQjua237mI7sK59GEyTKte4fQL3mLcXO-_8' })
//     .then((currentToken) => {
//         if (currentToken) {
//             // Send the token to your server and update the UI if necessary
//             // ...
//         } else {
//             // Show permission request UI
//             console.log('No registration token available. Request permission to generate one.');
//             // ...
//         }
// }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
// });
